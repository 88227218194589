<template>
  <div>
    <Message :type="messageTypeFailure" :message="message" />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { SupportApi } from '@/api'
import {
  BACKOFFICE_SUPPORT_ACCESS,
  ResponseState,
  MessageTypeEnum,
  RouteName,
  Module,
  UPDATE_PERSONAL_INFO,
  SET_USER_EMAIL,
} from '@/constants'
import { navigateTo } from '@/helpers'

export default {
  name: BACKOFFICE_SUPPORT_ACCESS,
  components: {
    Message: () => import('@/components/atoms/Message'),
  },
  data: () => ({
    message: '',
  }),
  computed: {
    messageTypeFailure: () => MessageTypeEnum.FAILURE,
  },
  methods: {
    ...mapActions(Module.USER, [UPDATE_PERSONAL_INFO, SET_USER_EMAIL]),
    async getAccess({ token }) {
      try {
        const { state, msg } = await SupportApi.getSupportAccess({ token })

        if (state === ResponseState.ERROR) {
          this.message = msg
          return
        }

        await this.UPDATE_PERSONAL_INFO()
        navigateTo({ name: RouteName.USER_ACCOUNT })
      } catch (e) {
        console.log(e)
      }
    },
  },
  created() {
    const token = this.$route.query.token

    this.getAccess({ token })
  },
}
</script>
